import React from 'react';
import { graphql } from 'gatsby';
import { ArticleRollTemplate } from '../../templates/article-roll';

const AllArticlesRollPage = ({ pageContext, data }) => {
  return <ArticleRollTemplate {...pageContext} {...data.allMarkdownRemark} />;
};

export default AllArticlesRollPage;

export const pageQuery = graphql`
  query AllArticles {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-post" }
        }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            type
            group
            title
            date(formatString: "YYYY-MMMM-DD")
          }
        }
      }
    }
  }
`;
